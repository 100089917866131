.wrapper {
	display: grid;
	height: 2024px;
	width: 100%;
	grid-template-columns: auto 270px 1200px auto;
	grid-template-rows: 120px auto 120px;
	grid-template-areas:
		' header header header  header'
		'.  sidebar main  . '
		'footer  footer  footer footer ';
	background-color: #f5f0ec;
}
@media screen and (max-width: 1440px) {
	.wrapper {
		grid-template-columns: auto 240px 1000px auto;
	}
}

/* @media screen and (max-width: 425px) {
	.wrapper {
		grid-template-columns: 425px;
		width: 425px;
		grid-template-areas:
			' header '
			' main'
			' footer';
	}
} */
