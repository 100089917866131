.logo {
	background-color: #ffd100;
	grid-area: logo;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo img {
	width: 156px;
	height: 48px;
}
.logo::after {
	content: '';
	position: relative;
	z-index: 0;
	border-left: 59px solid #ffd100;
	border-right: 0 solid #ffd100;
	border-bottom: 122px solid transparent;
	bottom: 0;
	top: 0;
	height: 0;
}
.logo::before {
	content: '';
	position: relative;
	z-index: 0;
	border-right: 60px solid #ffd100;
	border-left: 0 solid #ffd100;
	border-top: 121px solid transparent;
	bottom: 0;
	top: 0;
	height: 0;
	right: 2px;
}
/* 
@media screen and (max-width: 425px) {
	.logo {
		width: 166px;
		height: 120px;
	}
	.logo::before {
		display: none;
	}
	.logo::after {
		display: none;
	}
} */
