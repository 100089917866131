.products {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	align-self: flex-start;
}

.products a {
	text-decoration: none;
}

.cards {
	margin-left: 25px;
	margin-bottom: 20px;
	height: 500px;
	width: 300px;
	border-radius: 5px;
}

.cards img {
	border-radius: 2px;
	width: 100%;
	height: 325px;
	background: white;
}
.cards:hover {
	box-shadow: 0 0 5px 3px #c7c7cc;
}

.cards p {
	text-align: center;
	color: black;
	font-size: 14px;
	margin: 10px 0;
}
.cards .products-name {
	text-align: center;
	margin: 10px 0;
	font-size: 18px;
}
.cards h4 {
	color: black;
	font-size: 20px;
	text-align: center;
	margin: 10px 0;
}

.products-container {
	display: block;
	width: 1000px;
}

.products-pagination {
	display: flex;
	justify-content: center;
}

.products-warp {
	display: block;
	height: 1600px;
}
