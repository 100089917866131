.reviews_analytics {
	text-align: justify;
	font-size: 20px;
}
.reviews h3 {
	text-align: center;
}
.reviews ul {
	list-style: none;
}

.reviews a {
	display: block;
	text-decoration: none;
	margin: 10px 0;
}
.reviews a:hover {
	color: black;
}
