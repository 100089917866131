.product-container {
	display: flex;
}

.images-container {
	display: block;
}

.images-container img {
	width: 500px;
	height: 500px;
	border-radius: 10px;
}

.images-small-slid {
	display: flex;
	justify-content: center;
	width: 500px;
	height: 230px;
	flex-wrap: wrap;
}

.images-small-slid img {
	width: 100px;
	height: 100px;
	margin: 10px;
}

.act {
	border: 4px solid red;
}

.product-info {
	display: block;
	margin-left: 20px;
	width: 950px;
}
.product-info h2 {
	font-size: 32px;
	color: green;
}
.product-info h3 {
	font-size: 24px;
}

.product-info p {
	font-size: 18px;
	text-align: justify;
}
.product-info pre {
	font-family: 'Roboto';
	font-size: 18px;
	white-space: pre-wrap;
}
