.slider {
	width: 870px;
	height: 500px;
}
.slider img {
	width: 870px;
	height: 500px;
}

.products-top {
	display: block;
	width: 870px;
	height: 500px;
	margin-bottom: 50px;
}

.products-top img {
	width: 870px;
	height: 500px;
}
