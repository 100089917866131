.typeCamouflage {
	text-align: justify;
	font-size: 20px;
}
.typeCamouflage h2 {
	text-align: center;
}

.typeCamouflage img {
	width: 650px;
	height: 400px;
}
