.footer {
    grid-area: footer;
    display: grid;
    grid-template-columns:  auto 1050px 300px auto;
    grid-template-rows: 120px;
    background-color: #19191b;
    grid-template-areas:
    " . information icons  .";
    
}


.information{
    grid-area: information;
    height: 120px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.information a {
    text-decoration: none;
    color: white;
    font-size: 16pt;
}


.information a:hover{
        color: #ffd100;
}

.icons  {
   grid-area: icons;
    height: 120px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.icon{
    display: block;
    text-indent: -9999px;
    width: 36px;
    height: 36px;
    background-size: 36px 36px;
}


.instagram{
background-image:  url("./icons/instagram.svg");

}
.instagram:hover{
    background-image:  url("./icons/instagram2.svg");
}
.facebook {
    background-image: url("./icons/facebook.svg");
}

.facebook:hover{
    background-image: url("./icons/facebook2.svg");
}

.twitter {
    background-image: url("./icons/twitter.svg");
}
.twitter:hover{
    background-image: url("./icons/twitter2.svg");
}

.whatsapp{
    background-image: url("./icons/whatsapp.svg");
}
.whatsapp:hover{
    background-image: url("./icons/whatsapp2.svg");
}
.youtube{
    background-image: url("./icons/youtube.svg");
}
.youtube:hover{
    background-image: url("./icons/youtube2.svg");
}

.information_items{
    display: flex;
    align-items: center;
    color: white;
}
.information_items p{
    padding-left: 10px;
}
.information_items ul{
   margin: 0;
   padding-left: 10px;
}
.information_items ul li{
 margin-top: 7px;
  list-style: none;
 }