.header {
	grid-area: header;
	display: grid;
	grid-template-columns: auto 160px 1100px auto;
	grid-template-rows: 120px;
	background-color: #19191b;
	grid-template-areas: ' . logo nav  .';
	top: 0px;
	z-index: 2;
}

.header ul {
	list-style: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	justify-content: space-evenly;
	height: 120px;
}
.header ul a {
	text-decoration: none;
	color: #fff;
	display: flex;
	align-items: center;
	height: 120px;
	width: 20%;
	justify-content: center;
	transition: 500ms;
}
.header ul a:nth-child(1) {
	padding-left: 115px;
}

.header ul a:hover {
	color: #ffd100;
}

.nav {
	grid-area: nav;
	font-size: 16pt;
}
.nav-button {
	display: none;
}

/* @media screen and (max-width: 425px) {
	.nav {
		display: none;
	}
	.nav-button {
		display: flex;
		grid-area: nav-button;
		background-color: #ffd100;
		justify-content: center;
	}

	.header {
		grid-template-columns: 160px 265px;
		grid-template-rows: 120px;
		grid-template-areas: 'logo nav-button';
	}
} */
