.sidebar {
	grid-area: sidebar;
}
.sidebar-products ul {
	flex-direction: column;
	list-style: none;
	justify-content: space-between;
	display: flex;
	height: 900px;
	margin: 20px 0;
	padding: 0;
}
.sidebar-products {
	margin-top: 50px;
	border: 2px solid #e9e2dd;

	background-color: #fff;
	/* box-shadow: 4px 2px 2px 1px rgb(145, 145, 145); */
}
.sidebar-products ul li h3 {
	margin-bottom: 0;
	margin-right: 0;
}
.sidebar-products ul a {
	display: block;
	text-decoration: none;
	color: #666;
	height: 20px;
	font-size: 13pt;
	margin-left: 10px;
	transition: 500ms;
}

.sidebar-products p {
	margin-bottom: 10px;
	margin-left: 10px;
}

.sidebar-products ul a:hover {
	color: #000;
}
.sidebar-title {
	display: block;
	margin-top: 10px;
	font-size: 20pt;
	text-align: center;
}

.side-link {
	text-decoration: none;
	font-size: 20px;
	color: #666;
}
.side-link:hover {
	color: #000;
}
