body {
  margin: 0;
	padding: 0;
  font-family:  'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F0EC;

}

*{
  box-sizing: border-box;
}
